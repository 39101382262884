import React, {useState, useCallback} from 'react'
import {Link} from 'gatsby'
import style from "./SiteCard.module.scss"
import {Collapse} from 'react-collapse';
import SlideLeftOnAppear from "../components/SlideLeftOnAppear";


export function SiteCardTitle(props) {
    <h3 className="w-full font-bold text-2xl px-6 text-indigo-700 text-center uppercase">{props.children}</h3>
}

function SiteCard({children, title, white, purple, nb, onEnterCallBack, onLeaveCallBack}) {
   
    return (
        <div   className="relative cursor-pointer w-full md:w-1/2">
            <SlideLeftOnAppear loaded={true}>
                <div className="w-full p-6 flex flex-col flex-grow flex-shrink ">
                    <div className={` flex-1 bg-white rounded-t rounded-b-none overflow-hidden purple-shadow radius-5px py-6 transition-width ${purple ? 'gradient' : ''}`}>
                        
                            <h3  className={`w-full  text-3xl px-6  text-center tracking-widest uppercase anton ${purple ? 'text-white': 'text-indigo-700'}`}>{title}</h3>
                            <div className="w-full mb-4">
                                <div className={`h-1 mx-auto ${purple ? 'bg-white' : 'gradient'} w-1/4 transition-width opacity-50 my-0 py-0 rounded-t`}></div>
                            </div>
                            <h4></h4>
                          
                                <article  className={`${purple ? 'text-gray-100' : 'text-gray-600'} text-base px-6 mb-5`}>
                                    {children}
                                </article>
                            
                        
                    </div>
                </div>
            </SlideLeftOnAppear>
        </div>
        
        
    )
}

export default SiteCard
