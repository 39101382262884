import React from 'react'
import QuickContactForm from './QuickContactForm'
import Modal from 'react-modal';
import style from './ModalQuickContact.module.scss'

function ModalQuickContact({modalIsOpen, afterOpenModal, closeModal, setModalIsOpen}) {
  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      border                : 'none',
      background            : 'white'

    },
    overlay: {
      background            : 'linear-gradient(60.94deg, #007dfbc9 -62.93%, #661b80d9 51.64%, #661b80 213.13%)'
    },
  };
    return (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Modal contact demande devis rapide"
        >
          <div onClick={() => setModalIsOpen(false)} className={style.closeButton} />
          <div className="px-5">
            <p className="text-2xl text-gray-700 mb-1 text-center anton">Formulaire de contact pour devis</p>
            <div className="w-full mb-4">
              <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>
            <p className="text-gray-7600 mt-6">Merci de remplir les information ci-dessous, et je reviendrai vers vous rapidement.</p>
          </div>
            <QuickContactForm setModalIsOpen={setModalIsOpen} />
        </Modal>
    )
}

export default ModalQuickContact
