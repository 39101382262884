import React from 'react'
import style from "./Bubble.module.scss"
import portrait from "../images/portrait.png"

function Bubble({className, setmodalQuickContact}) {
    return (
        <div className="flex">
            <img src={portrait} className="rounded-full h-28 p-2 relative top-16 right-10 purple-shadow-2 bg-white" />
            <div className={`${style.bubble + ' ' + className} text-gray-800 anton text-xl purple-shadow-2`}>
                
                <h2 className="text-gray-800 anton">Bienvenue dans votre agence web J-RD</h2>
                <p>Bonjour, je m'appel <span className="lobster">Jérémy</span> !</p>
                <br />
                <p className="text-base">
                    Voici les différents types de site web que je propose <br />
                    Posez-moi vos questions ou dites-moi quel type de site vous intéresse et je reviendrai vers vous rapidement.
                </p>
                <button id="call-to-action-1" onClick={() => setmodalQuickContact(true)} className="mt-10 mx-auto hover:underline focus:outline-none font-bold rounded-sm py-4 block tracking-widest px-8 purple-shadow gradient text-white uppercase shadow-31 text-2xl">Devis Gratuit</button>

            </div>
        </div>
    )
}

export default Bubble
