import React, {useState, useCallback} from 'react'
import style from "./ProcessLine.module.scss"

function ProcessLine({image, title, flip, children, titleColor, alt}) {
    const [selected, setselected] = useState(false)

    const onEnter = useCallback(
        () => {
            setselected(true)
        },
        [selected],
    )

    const onLeave = useCallback(
        () => {
            setselected(false)
        },
        [selected],
    )

    return (
        <div className={`flex flex-wrap ${!flip ? 'flex-col' : 'flex-col-reverse'} sm:flex-row`} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            {
                !flip ? 
                <>
                    <div className="w-full sm:w-1/2 p-3 mt-6 flex justify-center">
                        <img src={image} alt={alt || "processus création site internet"} className={style.image} />
                    </div>
                    <div className="w-full sm:w-1/2 p-3 mt-6 flex items-center justify-start ">
                        <div className="align-middle w-11/12 sm:w-3/4">
                        <h3 style={{color: titleColor}} className={`font-bold text-3xl sm:text-4xl text-gray-200 leading-none uppercase mb-3 text-center ${selected ? style.titleUp : ''} transition-transform`}>{title}</h3>
                        <div className="w-full mb-4">
                            <div className={`h-1 mx-auto gradient ${selected ? 'w-4/5' : 'w-64'} transition-width opacity-50 my-0 py-0 rounded-t`}></div>
                        </div>
                        <article  className={`text-gray-100 mb-8  ml-4 bb relative ${style.line} ${selected ? style.lineSelected : ''}`}>
                            {children}
                        </article>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="w-full sm:w-1/2 p-3 mt-6 flex justify-end items-center">
                        <div className="align-middle w-11/12 sm:w-3/4">
                        <h3 style={{color: titleColor}} className={`font-bold text-3xl sm:text-4xl text-gray-200 leading-none uppercase mb-3 text-center ${selected ? style.titleUp : ''} transition-transform`}>{title}</h3>
                        <div className="w-full mb-4">
                            <div className={`h-1 mx-auto gradient ${selected ? 'w-4/5' : 'w-64'} transition-width opacity-50 my-0 py-0 rounded-t`}></div>
                        </div>
                        <article  className={`text-gray-200 mb-8  ml-4 bb relative ${style.lineFlip} ${selected ? style.lineFlipSelected : ''}`}>
                            {children}
                        </article>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 p-3 mt-6 flex justify-center">
                        <img src={image} className={style.image} />
                    </div>
                </>
            }
              
              
            </div>
    )
}

export default ProcessLine
